declare global {
  interface Window {
    grecaptchaReady: (callback: () => void) => void
    grecaptcha: Record<string, any>
  }
}

let head, badgeVisibility, badgePosition

export default () => {
  const { configRecaptchaKey } = useFeatureFlags()

  if (!head) {
    badgeVisibility = useStyleTag('.grecaptcha-badge { visibility: hidden; }', { manual: true })
    badgePosition = useStyleTag('.grecaptcha-badge { bottom: 0 !important; }', { manual: true })

    head = useHead({
      script: [
        {
          src: `https://www.google.com/recaptcha/enterprise.js?render=${configRecaptchaKey}`,
          onload: () => badgePosition.load()
        }
      ]
    }, {
      mode: 'client'
    })

    if (import.meta.client) {
      const c = '___grecaptcha_cfg'
      const windowC = (window[c] = window[c] || {})
      const fns = (windowC.fns = windowC.fns || [])

      window.grecaptchaReady ||= function (cb) {
        if (typeof window.grecaptcha === 'undefined') fns.push(cb)
        else cb()
      }
    }
  }

  tryOnMounted(() => {
    badgeVisibility.unload()
    badgePosition.load()
  })
  tryOnBeforeUnmount(() => {
    badgeVisibility.load()
    badgePosition.unload()
  })

  return {
    execute: (action) => new Promise<string>((resolve, reject) =>
      window.grecaptchaReady(() =>
        window.grecaptcha.enterprise.execute(configRecaptchaKey, { action })
          .then(resolve)
          .catch(reject)
      )
    )
  }
}
